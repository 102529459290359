import React, {useEffect, useState} from 'react';
import {useDispatchEx, useSelectorEx} from "../../hooks/redux";
import {useFormik} from "formik";
import {valSchemaSettings} from "../../helpers/schemas";

import {EditorState} from "draft-js";
import { asyncCreateCategoryAction } from '../../redux/slices/categories/categoriesAction';
import HeaderPanel from "../../components/molecules/HeaderPanel/HeaderPanel";

import FormCategory from "../../components/molecules/FormCategory/FormCategory";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {changeRedirect} from "../../redux/slices/products/productsSlice";

const CreateCategory = () => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const dispatch = useDispatchEx();

    const config = useFormik({
        initialValues: {
            title: '',
            description: '',
            image: ''
        },
        validationSchema:valSchemaSettings,
        onSubmit: (value) => formHandle(value),
    });

    const formHandle = (value: any)=>{
        dispatch(asyncCreateCategoryAction(value));
        config.resetForm();
        setEditorState(EditorState.createEmpty());
    };
    const { t } = useTranslation();

    const {shouldRedirectCategories} = useSelectorEx(state => state.categories)

    const navigate = useNavigate();
    useEffect(() => {
        if(shouldRedirectCategories){
            navigate("/categories");
            dispatch(changeRedirect(false))
        }
    }, [shouldRedirectCategories]);
    return (
        <>
            <div className="container">
                <HeaderPanel title={t('categories_pages.create.title')} onSave={()=>config.handleSubmit()}  />
                <FormCategory
                    editorState={editorState}
                    setEditorState={setEditorState}
                    title={''}
                    config={config}
                />

            </div>

        </>
    );
};

export default CreateCategory;