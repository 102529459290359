import {createAsyncThunk} from "@reduxjs/toolkit";
import ProductsService from "../../../api/ProductsService";
import {IAttribute, ICategory, IProduct} from "../../../helpers/types";
import {replaceEnterWithBr} from "../../../helpers/functions";




export const asyncGetSearchProductsAction = createAsyncThunk(
    'products/search',
    async (query:any, thunkAPI)=>{
        try {
            const {search,sort,page,category,limit} = query;
            let response = await ProductsService.findAll({search,sort, limit, page, category});
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);

export const asyncGetProductsAction = createAsyncThunk(
    'products/all',
    async (query:any, thunkAPI)=>{
        try {
            const {sort,page,category,limit} = query;
            let response = await ProductsService.findAll({sort, limit, page, category});
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);
export const asyncGetProductAction = createAsyncThunk(
    'products/one',
    async (id:number, thunkAPI)=>{
        try {
            let response = await ProductsService.findOne(id);
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);

export const asyncCreateProductAction = createAsyncThunk(
    'products/create',
    async (product:any, thunkAPI)=>{
        try {
            const {slug,
                isNewest,
                isBest,
                typesName,
                sale,
                seo_title,
                seo_description,
                sub_title,
                description,
                //colors,
                title,
                content,
                image,
                price,
                gallery,
                category,
                isAvailable,
                attributes,

                min_width,
                max_width,
                min_height,
                max_height,
                price_metric,
                section_count,
                open_parts

            } = product;




            const formData = new FormData();

            formData.append('typesName', typesName as string);
        //    formData.append('types', JSON.stringify(newTypes) as string);
            formData.append('slug', slug as string);
            formData.append('seo_title', seo_title as string);
            formData.append('seo_description', seo_description as string);
            formData.append('sub_title', sub_title as string);
            formData.append('description', description as string);
      ///      formData.append('colors', JSON.stringify(colors) as string);
            formData.append('title', title as string);
        //    formData.append('brand', brand as string);
            formData.append('sale', sale as string);
            formData.append('content', content as string);
            formData.append('price', Number(price) as any);
            formData.append('isAvailable', isAvailable as any);
            formData.append('isNewest', isNewest as any);
            formData.append('isBest', isBest as any);

            formData.append('min_width', min_width as any);
            formData.append('max_width', max_width as any);
            formData.append('min_height', min_height as any);
            formData.append('max_height', max_height as any);
            formData.append('price_metric', price_metric as any);
            formData.append('section_count', section_count as any);
            formData.append('open_parts', JSON.stringify(open_parts) as any);


            //       formData.append('infoAttributes', JSON.stringify(infoAttributes) as any);

            formData.append('categoryId', category as any);
            formData.append('attributes', JSON.stringify(attributes) as string);

            if(gallery && gallery.length > 0){
                for(let file of gallery as File[]) {
                    formData.append('gallery', file as File, 'image.jpg');
                }
            }
            else formData.append('gallery', '');

            if(typeof image === "string") formData.append('image', image as string);
            else formData.append('image', image as File, 'image.jpg');

            let response = await ProductsService.create(formData);
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);

export const asyncUpdateProductAction = createAsyncThunk(
    'products/update',
    async (product:any, thunkAPI)=>{
        try {
            const {slug,
                isNewest,
                isBest,
                sale,
                seo_title,
                seo_description,
                sub_title,
                description,
                id,
                title,
                content,
                image,
                price,
                gallery,
                oldGallery,
                category,
                isAvailable,
                attributes,

                min_width,
                max_width,
                min_height,
                max_height,
                price_metric,
                section_count,
                open_parts


            } = product;



            const formData = new FormData();

            formData.append('slug', slug as string);
            formData.append('seo_title', seo_title as string);
            formData.append('seo_description', seo_description as string);
            formData.append('sub_title', sub_title as string);
            formData.append('description', description as string);
            formData.append('title', title as string);
            formData.append('sale', sale as string);
            formData.append('content', content as string);
            formData.append('price', Number(price) as any);
            formData.append('isAvailable', isAvailable as any);
            formData.append('isNewest', isNewest as any);
            formData.append('isBest', isBest as any);
            formData.append('categoryId', category as any);
            formData.append('attributes', JSON.stringify(attributes) as string);
            formData.append('oldGallery', oldGallery as string);

            formData.append('min_width', min_width as any);
            formData.append('max_width', max_width as any);
            formData.append('min_height', min_height as any);
            formData.append('max_height', max_height as any);
            formData.append('price_metric', price_metric as any);
            formData.append('section_count', section_count as any);
            formData.append('open_parts', JSON.stringify(open_parts) as any);

            if(gallery && gallery.length > 0){
                for(let file of gallery as File[]) {
                    formData.append('gallery', file as File, 'image.jpg');
                }
            }
            if(typeof image === "string" || image === null)
            {
                formData.append('image', image as string);
            }
            else{
                formData.append('image', image as File, 'image.jpg');
            }

            let response = await ProductsService.update(formData, id);
            if(!response.hasOwnProperty('data')) {
                return thunkAPI.rejectWithValue(response)
            }
            return response.data;
        } catch (e:any) {
           // console.log('eeee',e)
            return thunkAPI.rejectWithValue(e.response?.data)
        }
    }
);

export const asyncDeleteProductAction = createAsyncThunk(
    'products/delete',
    async (id:number, thunkAPI)=>{
        try {
            let response = await ProductsService.delete(id);
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);


export const asyncImportProductAction = createAsyncThunk(
    'products/import',
    async (product:any, thunkAPI)=>{
        try {
            const {file} = product;

            const formData = new FormData();
            formData.append('file', file);

            let response = await ProductsService.import(formData);
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);
