import React, {FC, useEffect, useState} from 'react';
import {convertToRaw, EditorState} from "draft-js";
import { FormikValues} from "formik";
import draftToHtml from "draftjs-to-html";


import {IAttribute, IProduct} from "../../../helpers/types";
import './FormCategory.scss'
import UploaderImage from '../../atoms/UploaderImage/UploaderImage';
import Input from "../../atoms/Input/Input";
import ErrorMessage from "../../old/atoms/ErrorMessage/ErrorMessage";
import TextArea from "../../atoms/TextArea/TextArea";
import {Editor} from "react-draft-wysiwyg";
import Switch from '../../atoms/Switch/Switch';
import {useTranslation} from "react-i18next";


interface IProductForm {
    title: string;
    config: FormikValues;
    image?:string;
    gallery?:string[];
    editorState: any;
    setEditorState: (value:any)=>void;

    product?: IProduct | null
}

const FormCategory:FC<IProductForm> = ({title, config, editorState, setEditorState,image, gallery, product}) => {
    const {values, errors, touched, isSubmitting, handleBlur, handleChange, handleSubmit, setFieldValue, resetForm} = config;

    const onImageStateChange = (e:React.ChangeEvent<HTMLInputElement>)=>{
        setFieldValue("image", e.target.files![0]);
    };

    const onEditorStateChange = (value:EditorState)=>{
        setEditorState(value);
        setFieldValue("description", draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };

    const { t } = useTranslation();

    return (
        <div className="form-product">
            <div className="form-product__wrapper">
                <div className="form-product__block">
                    <div className="form-product__images">
                        <div className="form-product__image">
                            <UploaderImage
                                title={t('elements.image')}
                                defaultValue={image}
                                value={values.image}
                                onChange={(e)=>setFieldValue("image", e.target.files![0])}
                                onDelete={()=>setFieldValue("image", '')}
                            />
                        </div>
                    </div>
                    <div className="form-product__inner">
                        <div className="form-product__checkboxs">
                            <div className="form-product__checkbox">
                                <span>{t('categories_pages.fields.show_main')}</span>
                                <Switch isChecked={values.isShow} onChange={(value)=>setFieldValue('isShow', value)} label={'isShow'}/>
                            </div>

                        </div>

                        <div className="form-product__input">
                            <Input className={errors.title && touched.title ? "error": ""} value={values.title} onBlur={handleBlur} onChange={handleChange} name={'title'} placeholder={t('categories_pages.fields.name')} />
                            <ErrorMessage message={errors.title && touched.title && errors.title}/>
                        </div>
{/*                        <div className="form-product__input">
                            <Input className={errors.section_title && touched.section_title ? "error": ""} value={values.section_title} onBlur={handleBlur} onChange={handleChange} name={'section_title'} placeholder={t('categories_pages.fields.section_name')} />
                            <ErrorMessage message={errors.section_title && touched.section_title && errors.section_title}/>
                        </div>*/}
                        <div className="form-product__input">
                            <Input className={errors.order && touched.order ? "error": ""} value={values.order} onBlur={handleBlur} onChange={handleChange} name={'order'} placeholder={t('categories_pages.fields.order')} />
                            <ErrorMessage message={errors.order && touched.order && errors.order}/>
                        </div>
                    </div>

                </div>
                <div className="form-product__block">
                    <div className="form-product__input">
                        <Editor
                            toolbar={{
                                options: ['inline',  'fontSize', 'list', 'textAlign', 'history']
                            }}
                            editorState={editorState}
                            toolbarClassName="form-product__editor-toolbar"
                            wrapperClassName="wrapperClassName"
                            editorClassName="form-product__editor"
                            onEditorStateChange={onEditorStateChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormCategory;