export function replaceEnterWithBr(text:string) {
    return text.replace(/\n/g, '<br/>');
}

export function replaceBrWithEnter(text:string) {
    return text.replace(/<br\s*\/?>/g, '\n');
}

export function generateSlug(text:string) {
    return text
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '-')           // Заменяет пробелы на дефисы
        .replace(/[^\w\-]+/g, '')       // Удаляет все не буквенно-цифровые символы кроме дефисов
        .replace(/\-\-+/g, '-')         // Удаляет лишние дефисы
        .replace(/^-+/, '')             // Удаляет дефисы в начале строки
        .replace(/-+$/, '');            // Удаляет дефисы в конце строки
}

export const optionsSystem = [
    {
        id: 1,
        name: "aluplast",
        subtitle: 'from $134'
    },
    {
        id: 2,
        name: "kommerling",
        subtitle: 'from $134'
    }
];

export const optionsProfile = [
    {
        id: 1,
        options: [
            {
                id: 1,
                title: 'IDEAL 4000',
                infoList: [
                    'Angular design',
                    '70 mm construction depth',
                    '2 seals'
                ]
            },
            {
                id: 2,
                title: 'IDEAL 5000',
                infoList: [
                    'Rounded design',
                    '70 mm construction depth',
                    '3 seals'
                ]
            },
            {
                id: 3,
                title: 'IDEAL 7000',
                infoList: [
                    'Offset design',
                    '85 mm construction depth',
                    '2 seals'
                ]
            },
            {
                id: 4,
                title: 'IDEAL 8000',
                infoList: [
                    'Angular design',
                    '85 mm construction depth',
                    '3 seals'
                ]
            },
            {
                id: 5,
                title: 'energeto® 8000',
                infoList: [
                    'Angular design',
                    '85 mm construction depth',
                    'Suitable for passive houses'
                ]
            },
            {
                id: 6,
                title: 'energeto® 8000 ED',
                infoList: [
                    'Angular design',
                    '85 mm construction depth',
                    'Suitable for passive houses'
                ]
            }
        ]
    },
    {
        id: 2,
        options: [
            {
                id: 1,
                title: 'IDEAL 4001',
                infoList: [
                    'Angular design',
                    '70 mm construction depth',
                    '2 seals'
                ]
            },
            {
                id: 2,
                title: 'IDEAL 5000',
                infoList: [
                    'Rounded design',
                    '70 mm construction depth',
                    '3 seals'
                ]
            },
            {
                id: 3,
                title: 'IDEAL 7000',
                infoList: [
                    'Offset design',
                    '85 mm construction depth',
                    '2 seals'
                ]
            },
            {
                id: 4,
                title: 'IDEAL 8000',
                infoList: [
                    'Angular design',
                    '85 mm construction depth',
                    '3 seals'
                ]
            },
            {
                id: 5,
                title: 'energeto® 8000',
                infoList: [
                    'Angular design',
                    '85 mm construction depth',
                    'Suitable for passive houses'
                ]
            },
            {
                id: 6,
                title: 'energeto® 8000 ED',
                infoList: [
                    'Angular design',
                    '85 mm construction depth',
                    'Suitable for passive houses'
                ]
            }
        ]
    }
];

export const optionsColor = [
    {
        id: 1,
        subtitle: 'white'
    },
    /*    {
            id: 2,
            image: colorImg2.src,
            subtitle: 'Golden Oak'
        },
        {
            id: 3,
            image: colorImg3.src,
            subtitle: 'Walnut'
        },*/
    {
        id: 2,
        subtitle: 'Anthracite comparable'
    }
];

export const optionsHandle = [
    {
        id: 1,
        subtitle: 'Handle 1'
    },
    {
        id: 2,
        subtitle: 'Handle 2'
    },
    {
        id: 3,
        subtitle: 'Handle 3'
    },
    {
        id: 4,
        subtitle: 'Handle 4'
    }
];

export const optionsConnection = [
    {
        id: 1,
        subtitle: 'Standard (45 degrees)'
    },
    {
        id: 2,
        subtitle: 'HVL (90degrees)'
    }
];

export const optionsSash = [
    {

        id: 1,
        subtitle: 'Interior frame'

    },
    {
        id: 2,
        subtitle: 'Interior sash'

    },
    {
        id: 3,
        subtitle: 'Exterior frame'

    },
    {
        id: 4,
        subtitle: 'Exterior sash'

    },
];

export const optionsSashColor = [
    {
        id: 1,
        subtitle: 'white'
    },
    {
        id: 2,
        subtitle: 'Golden Oak'
    },
    {
        id: 3,
        subtitle: 'Walnut'
    },
    {
        id: 4,
        subtitle: 'Anthracite comparable'
    }
];

export const optionsHandleColor = [
    {
        id: 1,
        subtitle: 'white'
    },
    {
        id: 2,
        subtitle: 'Golden Oak'
    },
    {
        id: 3,
        subtitle: 'Walnut'
    },
    {
        id: 4,
        subtitle: 'Anthracite comparable'
    }
];


export const optionsGlassType = [
    {
        id: 1,
        subtitle: 'Double glazing'
    },
    {
        id: 2,
        subtitle: 'Triple glazing'
    },
    {
        id: 3,
        subtitle: 'Double glazing is secured'
    },
    {
        id: 4,
        subtitle: 'Triple glazing is secured'
    }
];


export const optionsOpening = [
    /*    {

            id: 1,
            image: openingImg1.src,

        },*/
    {
        id: 1,
        subtitle:"Left"

    },
    {
        id: 2,
        subtitle:"Right"

    },
    /*    {
            id: 4,
            image: openingImg4.src,

        },*/
];

export function calculateFinishedPrice(product: any, values: any, sectionCount: number): number {
    const priceMetric = product?.price_metric ? product.price_metric : 1;
    const productWindowOpening = product?.open_parts ? JSON.parse(product.open_parts) : [];

    // Adding top/bottom side plus add left/right side
    const priceMainMetricWindow = priceMetric * ((values.width * 2 + values.height * 2) / 1000);

    // Adding borders inside
    const staticBordersSection = priceMetric * (((sectionCount - 1) * values.height) / 1000);



    // Calculate opening window systems
    let priceOpeningMetricWindow = 0;


    values.plane_division_custom.forEach((value:any, index:number) => {
        const currentOpening = productWindowOpening.filter((item:any) => item.id === index)[0];
        if (currentOpening) {
            priceOpeningMetricWindow += priceMetric * ((value.value * 2 + values.height * 2) / 1000);
        }

    });

    const finishedPrice = priceMainMetricWindow + staticBordersSection + priceOpeningMetricWindow;
    return finishedPrice;
}


export const getOptionsListProduct = (
    values: any,
    product: any,
    sectionCount:any
): any[] => {
    const productWindowOpening = product?.open_parts ? JSON.parse(product.open_parts) : [];

    let windowSizeList = ``;
    let namesHandleType = ``;

    values.plane_division_custom?.forEach((value:any, index:number) => {
        const currentOpening = productWindowOpening.find((item: any) => item.id === index);

        windowSizeList += !index ? `${value.value.toFixed(0)} mm` : ` x ${value.value.toFixed(0)} mm`;
        const handleOpening = values.handle_opening.find((item:any) => item.id === index)?.value;
        const typeOpening = handleOpening ? (handleOpening === 1 && currentOpening ? 'left' : (handleOpening === 2 && currentOpening) ? 'right' : "none") : "none";
        namesHandleType += `${index + 1} - ${typeOpening} `;
    });


    const nameSystem = optionsSystem.find(item => item.id === values.system)?.name || "—";
    const nameProfile = optionsProfile.find(item => item.id === values.system)?.options?.find((item:any) => item.id === values.profile)?.title || "—";
    const nameConnection = optionsConnection.find(item => item.id === values.connection)?.subtitle || "—";
    const planeDivision = [
        { value: '1', name: 'Divide according to ratio 1:1:1' },
        { value: '2', name: 'Divide according to ratio 1:2:1' },
        { value: '3', name: 'Divide according to the ratio 2:1:2' },
        { value: '4', name: 'Custom' }
    ];
    const namePlaneDivision = planeDivision.find(item => item.value === values.plane_division)?.name || "—";
    const nameBaseColor = optionsColor.find(item => item.id === values.base_color)?.subtitle || "—";

    const getSashColorForCategory = (categoryId: number): string | null => {
        const sashColor = values.sash_color_list.find((sash:any) => sash.id === categoryId);
        if (!sashColor) {
            return null;
        }
        const color = optionsSashColor.find(colorOption => colorOption.id === sashColor.color);
        return color ? color.subtitle : null;
    }

    let nameSashColors = ``;
    optionsSash.forEach((value, index) => {
        nameSashColors += `${value.subtitle} (${getSashColorForCategory(value.id)})${index <= optionsColor.length ? ", " : ""}`;
    });

    const nameGlassType = optionsGlassType.find(item => item.id === values.glass_type)?.subtitle || "—";
    const nameHandleType = optionsHandle.find(item => item.id === values.handle)?.subtitle || "—";
    const nameHandleColor = optionsHandleColor.find(item => item.id === values.handle_color)?.subtitle || "—";

    let nameUpsells = ``;
    values.upsells_vent.forEach((item:any, index:number) => {
        nameUpsells += `${index + 1} - ${item.value ? "Yes" : "No"} `;
    });

    return [
        { title: "Size:", value: `${values.width} mm x ${values.height} mm (${sectionCount}) ${windowSizeList}` },
        { title: "System:", value: nameSystem },
        { title: "Profile:", value: nameProfile },
        { title: "Connection:", value: nameConnection },
        { title: "Plane Division:", value: `${namePlaneDivision} (${windowSizeList})` },
        { title: "Base color:", value: nameBaseColor },
        { title: "Sash color:", value: nameSashColors },
        { title: "Glass type:", value: `${nameGlassType}, Mate: ${values.glass_type_mate ? "Yes" : "No"}` },
        { title: "Handle:", value: `${nameHandleType}, Handle color: ${nameHandleColor}` },
        { title: "Sides of opening windows:", value: namesHandleType },
        { title: "Upsells:", value: `Vents: ${nameUpsells} Security: ${values.upsells_extra_security ? "Yes" : "No"}` },
    ];
};