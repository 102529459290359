import React, {useEffect, useState} from 'react';

import { EditorState} from "draft-js";
import {useDispatchEx, useSelectorEx} from "../../hooks/redux";
import {useFormik} from "formik";
import {valSchemaSettings} from "../../helpers/schemas";
import { asyncCreateProductAction } from '../../redux/slices/products/productsAction';
import HeaderPanel from "../../components/molecules/HeaderPanel/HeaderPanel";
import FormProduct from "../../components/molecules/FormProduct/FormProduct";
import {useTranslation} from "react-i18next";
import {changeRedirect} from "../../redux/slices/products/productsSlice";
import {useLocation, useNavigate} from "react-router-dom";


const CreateProduct = () => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [editorsState, setEditorsState] = useState({});

    const dispatch = useDispatchEx();
    const config = useFormik({
        initialValues: {
            title: '',
            content: '',
            image: '',
            price: '',
            slug: '',
            seo_title: '',
            seo_description:'',
            sub_title: '',
            description: '',
            sale: 0,
            gallery: [],
            category: null,
            isAvailable: true,
            isNewest: true,
            isBest: false,
            attributes: [],

            price_metric:0,
            section_count: 0,
            open_parts: [],
            min_width: 0,
            max_width: 0,
            min_height:0,
            max_height:0,
        },
        validationSchema:valSchemaSettings,
        onSubmit: (value) => formHandle(value),
    });

    const formHandle = (value: any)=>{
        dispatch(asyncCreateProductAction(value));
        //config.resetForm();
        setEditorState(EditorState.createEmpty());
    };
    const { t } = useTranslation();

    const {shouldRedirectProduct} = useSelectorEx(state => state.products)
    const navigate = useNavigate();
    useEffect(() => {
        if(shouldRedirectProduct){
            navigate("/products");
            dispatch(changeRedirect(false))
        }
    }, [shouldRedirectProduct]);
    return (
        <>
            <div className="container">
                <HeaderPanel title={t('products_pages.create.title')} onSave={()=>config.handleSubmit()}  />
                <FormProduct
                    setEditorsState={setEditorsState}
                    editorsState={editorsState}
                    editorState={editorState}
                    setEditorState={setEditorState}
                    title={''}
                    config={config}
                />

            </div>
        </>
    );
};

export default CreateProduct;