import React, { useEffect, useState } from 'react';
import {
    asyncDeleteProductAction,
    asyncGetProductsAction,
    asyncImportProductAction
} from "../../redux/slices/products/productsAction";
import { connect, ConnectedProps } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { selectCategoriesToListList, selectProductsList } from "../../selectors/selectors";
import HeaderPanel from "../../components/molecules/HeaderPanel/HeaderPanel";
import CategoryProductList from "../../components/molecules/CategoryProductList/CategoryProductList";
import SearchBar from "../../components/atoms/SearchBar/SearchBar";
import SelectMenu from "../../components/atoms/SelectMenu/SelectMenu";
import { asyncGetCategoriesAction } from "../../redux/slices/categories/categoriesAction";
import Pagination from '../../components/atoms/Pagination/Pagination';
import {useParams} from "react-router-dom";
import PreloaderContent from "../../components/atoms/PreloaderContent/PreloaderContent";
import PlusIcon from "../../components/atoms/icons/PlusIcon";
import ButtonRect from "../../components/atoms/ButtonRect/ButtonRect";
import ImportIcon from "../../components/atoms/icons/ImportIcon";
import ModalMini from "../../components/atoms/ModalMini/ModalMini";
import UploaderFile from "../../components/atoms/UploaderFile/UploaderFile";
import {useDispatchEx} from "../../hooks/redux";
import {useTranslation} from "react-i18next";


const mapStateToProps = (state: RootState) => ({
    categories: selectCategoriesToListList(state),
    products: selectProductsList(state),
    totalProducts: state.products.totalProducts,
    isLoading: state.products.isLoading
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    loadCategories: () => dispatch(asyncGetCategoriesAction()),
    loadProducts: (query: any) => dispatch(asyncGetProductsAction(query)),
    deleteProduct: (id: number) => dispatch(asyncDeleteProductAction(id))
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Products: React.FC<PropsFromRedux> = ({
                                                categories,
                                                products,
                                                isLoading,
                                                totalProducts,
                                                loadCategories,
                                                loadProducts
                                            }) => {
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 10
    });
    const [currentCategory, setCurrentCategory] = useState<any>(null);
    const { pageNumber } = useParams();
    useEffect(() => {
        loadProducts({
            page: pageNumber ? Number(pageNumber) : 1,
            limit: 10,
            sort: 'asc'
        });
        loadCategories();
    }, [loadProducts, loadCategories,pageNumber]);

    useEffect(() => {
        setPagination((prev) => ({
            ...prev,
            total: totalProducts
        }));
    }, [totalProducts]);


    const handleChangeCategory = (category: any) => {
        setCurrentCategory(category);
        loadProducts({
            category: category,
            page: 1,
            limit: 10,
            sort: 'asc'
        });
    };

    const changePagination = (page: any) => {
        setPagination((prev) => ({
            ...prev,
            current: page
        }));
        loadProducts({
            category: currentCategory,
            page: page,
            limit: 10,
            sort: 'asc'
        });
    };

 //   if (!products || isLoading) return <Preloader />;
    const [openModal, setOpenModal] = useState(false);
    const [importFile, setImportFile] = useState<any>(null);

    const dispatch = useDispatchEx();
    const importProducts = ()=>{
        dispatch(asyncImportProductAction({
            file: importFile.file
        }))
    }//{t('menu.logout')}
    const { t } = useTranslation();
    return (
        <div className="container">
            <ModalMini active={openModal} onAccept={importProducts} onCancel={()=>setOpenModal(false)} onClose={()=>setOpenModal(false)} header={t('modals.import_modal.title')}>
                <UploaderFile
                    title={t('elements.file')}
                    defaultValue={''}
                    value={importFile}
                    onChange={(e)=> {
                      //  console.log('e.target.files![0]',e.target.files![0])
                       // setFieldValue("image", e.target.files![0])
                        setImportFile({
                            name: e.target.files![0].name,
                            format: e.target.files![0].type,
                            file:e.target.files![0]
                        })
                    }}
                    onDelete={()=>{}}
                />
            </ModalMini>
            <HeaderPanel title={`${t('products_pages.title')} (${pagination.total})`} addUrl={'/products/create'}
                additionalButtons={<>
                <ButtonRect onClick={()=>setOpenModal(true)} href={'#'} name={<ImportIcon/>} />

                </>}
            />
            {!isLoading ?
                <>
                    <SearchBar />
                    <div style={{ margin: '15px 0' }}>
                        <SelectMenu currentItem={currentCategory} onChange={(value) => handleChangeCategory(value)} items={[                        {
                            name: t('products_pages.AllProducts'),
                            value: null
                        },...categories]} />
                    </div>
                    <CategoryProductList link={'/products'} items={products} />
                    <Pagination
                        pageUrl={'/products'}
                        totalItems={totalProducts}
                        itemsPerPage={10}
                        currentPage={pagination.current}
                        onPageChange={changePagination}
                    />
                </>
                :
                <PreloaderContent/>
            }

        </div>
    );
};

export default connector(Products);
