import React, {FC, useEffect, useState} from 'react';
import {convertToRaw, EditorState} from "draft-js";
import { FormikValues} from "formik";
import draftToHtml from "draftjs-to-html";

import {useDispatchEx, useSelectorEx} from "../../../hooks/redux";
import {asyncGetAttributesAction} from "../../../redux/slices/attributes/attributesAction";
import {IAttribute, IProduct} from "../../../helpers/types";

import {asyncGetCategoriesAction} from "../../../redux/slices/categories/categoriesAction";

import {asyncGetInfoAttributesAction} from "../../../redux/slices/infoAttributes/infoAttributesAction";

import './FormProduct.scss'
import UploaderImage from '../../atoms/UploaderImage/UploaderImage';
import UploaderGallery from "../../atoms/UploaderGallery/UploaderGallery";
import Input from "../../atoms/Input/Input";
import ErrorMessage from "../../old/atoms/ErrorMessage/ErrorMessage";
import Select from "../../atoms/Select/Select";
import TextArea from "../../atoms/TextArea/TextArea";
import MultiSelect from '../../atoms/MultiSelect/MultiSelect';
import {Editor} from "react-draft-wysiwyg";
import Switch from '../../atoms/Switch/Switch';
import PreloaderContent from "../../atoms/PreloaderContent/PreloaderContent";
import {useTranslation} from "react-i18next";
import RepeaterCheckbox from "../RepeaterCheckbox/RepeaterCheckbox";
import {generateSlug} from "../../../helpers/functions";


interface IProductForm {
    title: string;
    config: FormikValues;
    image?:string;
    gallery?:string[];
    editorState: any;
    setEditorState: (value:any)=>void;
    editorsState: any;
    setEditorsState: (value:any)=>void;
    product?: IProduct | null
}

const FormProduct:FC<IProductForm> = ({title, config, editorState, setEditorState,editorsState, setEditorsState,image, gallery, product}) => {
    const {values, errors, touched, isSubmitting, handleBlur, handleChange, handleSubmit, setFieldValue, resetForm} = config;
    const onEditorStateChange = (value:EditorState)=>{
        setEditorState(value);
        setFieldValue("content", draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };

    const {attributes,isLoading} = useSelectorEx(state => state.attributes);
    const {infoAttributes} = useSelectorEx(state => state.infoAttributes);
    const {categories,isLoading:isLoadingCategory} = useSelectorEx(state => state.categories);

    const dispatch = useDispatchEx();
    useEffect(()=>{
        if(values.category){
      //      dispatch(asyncGetInfoAttributesAction(values.category));
            dispatch(asyncGetAttributesAction(values.category));
        }
        dispatch(asyncGetCategoriesAction());
    },[values.category]);

/*
    useEffect(()=>{
        if(infoAttributes?.length){
            const currentAttributes = infoAttributes![0]?.values ? infoAttributes![0]?.values.split(',') : [];
            currentAttributes.forEach((item,index)=>{
                setFieldValue(`infoAttributes[${index}].name`, item);
            })

        }
    },[infoAttributes]);*/


    const onChangeSelect = (value:any)=>{
        const newValues = value.map((item:string)=>JSON.parse(item));
        const newNameArray = newValues.map((item:IAttribute)=>item.name);
        setFieldValue('attributes', values.attributes.filter((item:IAttribute)=>newNameArray.includes(item.name)));
        setSelectedItems(newValues);
        //setSelectedItemsEx([...JSON.parse(value)]);
    };

    const onChangeChildrenSelect = (attribute:IAttribute, value:string[])=>{
        const getItem = values.attributes.filter((item:IAttribute)=>item.name === attribute.name);
        if(getItem.length){
            setFieldValue('attributes', values.attributes.map((item:IAttribute)=>{
                return item.name === attribute.name ? {...item, values: value.join(',')} : item
            }))
        }
        else{
            const newAttribute = {
                id: attribute.id,
                name: attribute.name,
                values: value.join(',')
            };
            setFieldValue('attributes', [...values.attributes, newAttribute])
        }
    };


    const [selectedItems, setSelectedItems] = useState<any>([]);
    const [selectedInfoItems, setSelectedInfoItems] = useState<any>(infoAttributes![0]?.values ? infoAttributes![0]?.values.split(',') : []);
    const [initDataProduct, setInitDataProduct] = useState<any>(null);
    const productAttr = product?.attributes?.map((item:any)=>item.id);


    useEffect(() => {
        if(attributes.length){
            const productAttrFiltered = attributes?.filter((item:IAttribute)=>productAttr?.includes(item.id));
            setSelectedItems(productAttrFiltered ? productAttrFiltered : [])
            setInitDataProduct({
                ...initDataProduct,
                selectedItems:productAttrFiltered
            })
        }
    }, [attributes]);


    useEffect(() => {
        if(values.category && !initDataProduct){

            setInitDataProduct({
                category: values.category,
                selectedItems:[],
                attributes: values.attributes,
            //    infoAttributes: values.infoAttributes,
                selectedInfoItems: selectedInfoItems,
            });

        }
    }, [product,values]);

/*    useEffect(() => {
        if(initDataProduct){
            setSelectedInfoItems(infoAttributes![0]?.values ? infoAttributes![0]?.values.split(',') : [])
        }
    }, [infoAttributes]);*/


    const [defaultAttributes, setDefaultAttributes] = useState<any>({})
    useEffect(() => {
        if( product?.attributes?.length){
            const arrayAttr:any = {};
            product?.attributes?.forEach(item=>{
                arrayAttr[item.id] = {
                    name: item.name,
                    values: item?.ProductAttribute?.values
                };
            });
            setDefaultAttributes(arrayAttr);

        }

    }, [product?.attributes]);


    const onDeleteUploadList = (id:number)=>{
        setFieldValue("oldGallery", values.oldGallery.split(',').filter((item:string, index:number)=>index !== id).join(','));
    };

    const onChangeCategory = (value:any)=>{
        //console.log("onChangeCategory",value,initDataProduct.category)
        setFieldValue('category', value);
        dispatch(asyncGetAttributesAction(value));
       // dispatch(asyncGetInfoAttributesAction(value));
        if(value === initDataProduct?.category){
            setSelectedItems(initDataProduct?.selectedItems ? initDataProduct?.selectedItems : [])
            setFieldValue('attributes', initDataProduct?.attributes);
            setSelectedInfoItems(initDataProduct?.selectedInfoItems);
        //    setFieldValue('infoAttributes', initDataProduct?.infoAttributes);
        }
        else {
            setSelectedItems([])
            setFieldValue('attributes', [])

    //        setSelectedInfoItems([]);
     //       setFieldValue(`infoAttributes`, []);
        }
    }

   // const [currentOpenParts, setCurrentOpenParts] = useState<number>(0);

    const { t } = useTranslation();
   // if(isLoading || !categories?.length) return <Preloader/>;
    return (
        <div className="form-product">
            {!isLoading ?
                <>
                    <div className="form-product__wrapper">
                        <div className="form-product__block">
                            <div className="form-product__images">
                                <div className="form-product__image">
                                    <UploaderImage
                                        title={t('elements.image')}
                                        defaultValue={values.image}
                                        value={values.image}
                                        onChange={(e)=>setFieldValue("image", e.target.files![0])}
                                        onDelete={()=>setFieldValue("image", '')}
                                    />
                                </div>
                                <div className="form-product__gallery">
                                    <UploaderGallery
                                        title={t('elements.gallery')}
                                        defaultValue={gallery}
                                        onChange={(gallery)=>setFieldValue('gallery', gallery)}
                                        onDelete={onDeleteUploadList}
                                    />
                                </div>
                            </div>
                            <div className="form-product__inner">
                                <div className="form-product__checkboxs">
                                    <div className="form-product__checkbox">
                                        <span>{t('products_pages.fields.isAvailable')}</span>
                                        <Switch isChecked={values.isAvailable} onChange={(value)=>setFieldValue('isAvailable', value)} label={'isAvailable'}/>
                                    </div>
                                    <div className="form-product__checkbox">
                                        <span>{t('products_pages.fields.isNewest')}</span>
                                        <Switch isChecked={values.isNewest} onChange={(value)=>setFieldValue('isNewest', value)} label={'isNewest'}/>
                                    </div>
                                    <div className="form-product__checkbox">
                                        <span>{t('products_pages.fields.isBest')}</span>
                                        <Switch isChecked={values.isBest} onChange={(value)=>setFieldValue('isBest', value)} label={'isBest'}/>
                                    </div>
                                </div>

                                <div className="form-product__input">
                                    <Input className={errors.title && touched.title ? "error": ""} value={values.title} onBlur={handleBlur} onChange={(e)=>{
                                        handleChange(e);
                                        setFieldValue('slug', generateSlug(e.target.value));
                                    }} name={'title'} placeholder={t('products_pages.fields.title')} />
                                    <ErrorMessage message={errors.title && touched.title && errors.title}/>
                                </div>
                                <div className="form-product__input">
                                    <TextArea className={errors.description && touched.description ? "error": ""} value={values.description} onBlur={handleBlur} onChange={handleChange} name={'description'} placeholder={t('products_pages.fields.description')} />
                                    <ErrorMessage message={errors.description && touched.description && errors.description}/>
                                </div>
                                <div className="form-product__input">
                                    <Input className={errors.price && touched.price ? "error": ""} value={values.price} onBlur={handleBlur} onChange={handleChange} name={'price'} placeholder={t('products_pages.fields.price')} />
                                    <ErrorMessage message={errors.price && touched.price && errors.price}/>
                                </div>

                                <div className="form-product__input">
                                    {categories?.length ?
                                        <Select
                                            type={'search'}
                                            defaultValue={values.category?.toString()}
                                            className={errors.category && touched.category ? "error": ""}
                                            onChange={onChangeCategory}
                                            placeholder={t('products_pages.fields.category')}
                                            items={categories.map((item:any)=>{
                                                return {
                                                    value: item.id.toString(),
                                                    name: item.title as string
                                                }
                                            })}
                                        />
                                        : ""}

                                </div>

                                <div className="form-product__input">
                                    <Input className={errors.slug && touched.slug ? "error": ""} value={values.slug} onBlur={handleBlur} onChange={handleChange} name={'slug'} placeholder={'Slug'} />
                                    <ErrorMessage message={errors.slug && touched.slug && errors.slug}/>
                                </div>
                                <div className="form-product__input">
                                    <Input className={errors.seo_title && touched.seo_title ? "error": ""} value={values.seo_title} onBlur={handleBlur} onChange={handleChange} name={'seo_title'} placeholder={'Seo title'} />
                                    <ErrorMessage message={errors.seo_title && touched.seo_title && errors.seo_title}/>
                                </div>
                                <div className="form-product__input">
                                    <Input className={errors.seo_description && touched.seo_description ? "error": ""} value={values.seo_description} onBlur={handleBlur} onChange={handleChange} name={'seo_description'} placeholder={'Seo Description'} />
                                    <ErrorMessage message={errors.seo_description && touched.seo_description && errors.seo_description}/>
                                </div>


                                <div className="form-product__input">
                                    <Input className={errors.sale && touched.sale ? "error": ""} value={values.sale} onBlur={handleBlur} onChange={handleChange} name={'sale'} placeholder={t('products_pages.fields.sale')} />
                                    <ErrorMessage message={errors.sale && touched.sale && errors.sale}/>
                                </div>
                            </div>

                        </div>
                        <div className="form-product__block">
                            <div className="form-product__input">
                                <Input className={errors.price_metric && touched.price_metric ? "error": ""} value={values.price_metric} onBlur={handleBlur} onChange={handleChange} name={'price_metric'} placeholder={'Price for m window'} />
                                <ErrorMessage message={errors.price_metric && touched.price_metric && errors.price_metric}/>
                            </div>
                            <div className="form-product__input-group">
                                <div className="form-product__input">
                                    <Input className={errors.min_width && touched.min_width ? "error": ""} value={values.min_width} onBlur={handleBlur} onChange={handleChange} name={'min_width'} placeholder={'Min Width'} />
                                    <ErrorMessage message={errors.min_width && touched.min_width && errors.min_width}/>
                                </div>
                                <div className="form-product__input">
                                    <Input className={errors.max_width && touched.max_width ? "error": ""} value={values.max_width} onBlur={handleBlur} onChange={handleChange} name={'max_width'} placeholder={'Max Width'} />
                                    <ErrorMessage message={errors.max_width && touched.max_width && errors.max_width}/>
                                </div>
                            </div>
                            <div className="form-product__input-group">
                                <div className="form-product__input">
                                    <Input className={errors.min_height && touched.min_height ? "error": ""} value={values.min_height} onBlur={handleBlur} onChange={handleChange} name={'min_height'} placeholder={'Min Height'} />
                                    <ErrorMessage message={errors.min_height && touched.min_height && errors.min_height}/>
                                </div>
                                <div className="form-product__input">
                                    <Input className={errors.max_height && touched.max_height ? "error": ""} value={values.max_height} onBlur={handleBlur} onChange={handleChange} name={'max_height'} placeholder={'Max Height'} />
                                    <ErrorMessage message={errors.max_height && touched.max_height && errors.max_height}/>
                                </div>
                            </div>

{/*                            <div className="form-product__input">
                                <Input className={errors.open_parts && touched.open_parts ? "error": ""} value={currentOpenParts.toString()} onBlur={handleBlur} onChange={(e)=>{
                                    if(!isNaN(Number(e.target.value)) ) {
                                        setCurrentOpenParts(Number(e.target.value))
                                    }

                                }} name={'open_parts'} placeholder={'Open part count'} />
                                <ErrorMessage message={errors.open_parts && touched.open_parts && errors.open_parts}/>
                            </div>*/}

                            <div className="form-product__input">
                                <Input className={errors.section_count && touched.section_count ? "error": ""} value={values.section_count} onBlur={handleBlur} onChange={(e)=>{
                                    if(!isNaN(Number(e.target.value)) && Number(e.target.value) <= 10) {
                                        setFieldValue('section_count',Number(e.target.value))
                                    }

                                }} name={'section_count'} placeholder={'Section count'} />
                                <ErrorMessage message={errors.section_count && touched.section_count && errors.section_count}/>
                            </div>

                            <RepeaterCheckbox
                               title={'Open parts'}
                               name={'open_parts'}
                               values={values.open_parts}
                               items={Array.from(Array(Number(values.section_count)).keys())}//values.open_parts
                               errors={errors.types}
                               touched={errors.types}
                               handleChange={(newItem)=>{
                                   const existingItemIndex = values.open_parts.findIndex((item:any) => item.id === newItem.id);

                                   if (existingItemIndex === -1) {
                                       setFieldValue('open_parts', [
                                           ...values.open_parts,
                                           newItem
                                       ]);
                                   } else {
                                       const updatedOpenParts = values.open_parts.map((item:any, index:number) =>
                                           index === existingItemIndex ? { ...item, value: newItem.value } : item
                                       );
                                       setFieldValue('open_parts', updatedOpenParts);
                                   }

                               }}
                               handleBlur={handleBlur}
                            />

{/*                            <div className="form-product__input">
                                <Input className={errors.open_parts && touched.open_parts ? "error": ""} value={values.open_parts} onBlur={handleBlur} onChange={handleChange} name={'open_parts'} placeholder={'Open part count'} />
                                <ErrorMessage message={errors.open_parts && touched.open_parts && errors.open_parts}/>
                            </div>*/}
                            <div className="form-product__input">
                                <MultiSelect
                                    // defaultValue={values.category?.toString()}
                                    //   className={errors.category && touched.category ? "error": ""}
                                    onChange={onChangeSelect}
                                    placeholder={t('products_pages.fields.attributes')}
                                    defaultValue={values.attributes?.map((attribute:any) =>{
                                        return attribute.name
                                    })}
                                    items={attributes?.map((item:any)=>{

                                        return {
                                            value: JSON.stringify(item),
                                            name: item.name as string
                                        }
                                    })}
                                />
                            </div>

                            {
                                selectedItems.length > 0 && selectedItems?.map((item:any)=>{
                                    return <div className={'form-product__input'} key={item.name+item.id}>
                                        <MultiSelect
                                            defaultValue={defaultAttributes![item.id]?.values.split(',')}
                                            className={errors.category && touched.category ? "error": ""}
                                            onChange={(val)=>onChangeChildrenSelect(item,val)}
                                            placeholder={item.name}
                                            items={item.values.split(',')?.map((i:any)=>{
                                                return {
                                                    value: i,
                                                    name: i
                                                }
                                            })}
                                        />

                                    </div>
                                })
                            }

                            <div className="form-product__input">
                                <Editor
                                    toolbar={{
                                        options: ['inline',  'fontSize', 'list', 'textAlign', 'history']
                                    }}
                                    editorState={editorState}
                                    toolbarClassName="form-product__editor-toolbar"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="form-product__editor"
                                    onEditorStateChange={onEditorStateChange}
                                />
                            </div>
                        </div>
                    </div>
                </>
                :
                <PreloaderContent/>
            }

        </div>
    );
};

export default FormProduct;