import React, {useState} from 'react';

import {Link} from "react-router-dom";
import {useDispatchEx, useSelectorEx} from "../../../hooks/redux";
/*import Preloader from "../../atoms/Preloader/Preloader";
import AvatarEx from "../../old/atoms/Avatar/Avatar";*/
import {asyncLogoutAction} from "../../../redux/slices/auth/authAction";

import './Sidebar.scss'
import BagIcon from "../../atoms/icons/BagIcon";
import TagIcon from "../../atoms/icons/TagIcon";
import CategoryIcon from "../../atoms/icons/CategoryIcon";
import CartIcon from "../../atoms/icons/CartIcon";
import InfoTagIcon from "../../atoms/icons/InfoTagIcon";
import CashIcon from "../../atoms/icons/CashIcon";
import UserIcon from "../../atoms/icons/UserIcon";
import {useTranslation} from "react-i18next";

const Sidebar = () => {
    const dispatch = useDispatchEx();
  //  const {user,isLoading} = useSelectorEx(state => state.auth);
  //  if(isLoading) return <Preloader/>;
    /*
    *
    *  <AvatarEx src={user?.avatar}/>
                <span className={'sidebar__name'}>{user?.email}</span>
    *
    * */
    const [burger,setBurger] = useState(false);
    const { t } = useTranslation();
    return (
        <aside className={`sidebar ${burger ? "active" : ""}`}>
            <div className="sidebar__wrapper">
                <div className="sidebar__header">
                    <div className="sidebar__logo">
                        <Link to={'/'}>
                            <svg width="95" height="30" viewBox="0 0 95 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.6174 2.29861L14.6389 0L4.6875 5.7448L18.6174 13.7882V2.29861Z" fill="#BB253F"/>
                                <path d="M19.3281 2.71094V18.7943L29.2726 13.053V8.45228L19.3281 2.71094Z" fill="#BB253F"/>
                                <path d="M3.9785 6.15625L0 8.4514V19.941L13.9299 11.8976L3.9785 6.15625Z" fill="#BB253F"/>
                                <path d="M29.2741 29.9826V13.875L15.3477 21.9184L29.2741 29.9826Z" fill="#BB253F"/>
                                <path d="M0 27.6641H9.94451V15.0234L0 20.7648V27.6641Z" fill="#A31332"/>
                                <path d="M10.6602 27.6633H23.878L10.6602 20.0312V27.6633Z" fill="#BB253F"/>
                                <path d="M0 8.4514L13.9299 11.8976L3.9785 6.15625L0 8.4514Z" fill="#A31332"/>
                                <path d="M14.6387 0.000244141L18.6172 13.7884V2.29885L14.6387 0.000244141Z" fill="#A31332"/>
                                <path d="M29.2726 8.45312L19.3281 18.7951L29.2726 13.0538V8.45312Z" fill="#A31332"/>
                                <path d="M15.3477 21.918L29.2741 24.0196V29.9821L15.3477 21.918Z" fill="#A31332"/>
                                <path d="M0 27.6641L9.94451 15.0234V27.6641H0Z" fill="#BB253F"/>
                                <path d="M23.878 27.6644L10.6602 25.1445V27.6644H23.878Z" fill="#A31332"/>
                                <path d="M44.5276 26.1676C43.3178 26.1676 42.3742 26.0017 41.6898 25.6733C41.0054 25.3449 40.5318 24.8507 40.2657 24.1939C39.9995 23.5372 39.8578 22.718 39.8405 21.7432C37.6179 21.4771 35.9311 20.6371 34.7767 19.2234C33.6222 17.8096 33.0449 15.7634 33.0449 13.0811C33.0449 9.90105 33.7743 7.61972 35.2295 6.23364C36.6847 4.84756 38.8105 4.15625 41.5999 4.15625C44.3893 4.15625 46.5117 4.84756 47.9703 6.23364C49.4255 7.61972 50.1549 9.90105 50.1549 13.0811C50.1549 15.7115 49.5984 17.7267 48.4888 19.1301C47.3793 20.5334 45.7651 21.3872 43.6531 21.6879C43.6531 22.2548 43.7568 22.6523 43.9607 22.8735C44.1647 23.0947 44.5172 23.2053 45.015 23.2053C45.1187 23.2053 47.3827 23.2053 47.3827 23.2053V26.1641H44.5345L44.5276 26.1676ZM41.5964 18.2002C43.0344 18.2002 44.0886 17.7855 44.7557 16.9593C45.4229 16.1332 45.7547 14.8405 45.7547 13.0811C45.7547 11.1627 45.4229 9.80772 44.7557 9.01617C44.0886 8.22462 43.0378 7.83057 41.5964 7.83057C40.1551 7.83057 39.0766 8.22462 38.413 9.01617C37.7458 9.80772 37.414 11.1627 37.414 13.0811C37.414 14.8405 37.7458 16.1332 38.413 16.9593C39.0801 17.7855 40.1412 18.2002 41.5964 18.2002Z" fill="#A31332"/>
                                <path d="M55.3479 21.8491C53.8546 21.8491 52.6932 21.4274 51.8567 20.584C51.0203 19.7406 50.6055 18.5101 50.6055 16.8924V8.94922H54.8951V16.6504C54.8951 17.3625 55.0679 17.881 55.4135 18.2093C55.7592 18.5377 56.2811 18.7036 56.9725 18.7036C57.4875 18.7036 57.9748 18.5965 58.438 18.3822C58.9012 18.1679 59.2814 17.9017 59.5856 17.5837V8.94922H63.8752V21.5795H59.8794L59.7446 20.5149C59.2296 20.9054 58.6074 21.2269 57.8781 21.4758C57.1487 21.7246 56.3053 21.8491 55.3479 21.8491Z" fill="#A31332"/>
                                <path d="M66.4603 21.5831V12.1225H64.5938L64.9671 8.95288H70.7499V21.5831H66.4603ZM66.1146 6.95153V3.72656H70.7775V6.95153H66.1146Z" fill="#A31332"/>
                                <path d="M72.1621 21.5839V8.95365H76.1579L76.2927 10.0459C76.8077 9.6899 77.4576 9.37535 78.2387 9.09883C79.0199 8.82231 79.8564 8.6875 80.7447 8.6875C82.3797 8.6875 83.5687 9.07809 84.3154 9.85927C85.062 10.6405 85.4353 11.8502 85.4353 13.4852V21.5874H81.1457V13.8309C81.1457 13.1188 80.9936 12.6141 80.6929 12.3134C80.3922 12.0127 79.8495 11.8606 79.0683 11.8606C78.6051 11.8606 78.135 11.9678 77.6546 12.1821C77.1741 12.3964 76.7766 12.6625 76.4552 12.9805V21.5874H72.1656L72.1621 21.5839Z" fill="#A31332"/>
                                <path d="M91.8829 21.8489C90.3205 21.8489 89.1695 21.4479 88.4332 20.6494C87.697 19.851 87.3271 18.7483 87.3271 17.345V12.2016H85.623V8.94899H87.3271V6.31164L91.6167 5.16406V8.94899H94.6827L94.496 12.2016H91.6167V17.0512C91.6167 17.6561 91.7688 18.0778 92.0695 18.3163C92.3702 18.5548 92.8231 18.6757 93.428 18.6757C93.943 18.6757 94.4684 18.5859 95.0007 18.4096V21.3131C94.1642 21.6691 93.1272 21.8454 91.8829 21.8454V21.8489Z" fill="#A31332"/>
                                <path d="M48.7657 25.2175V26.2165H48.1504V23.2266H48.7657V24.5435L49.934 23.2266H50.6598L49.4639 24.5124L50.7843 26.2165H50.048L49.0698 24.8961L48.7622 25.2175H48.7657Z" fill="#097AD5"/>
                                <path d="M53.0469 23.1797C53.4686 23.1797 53.8108 23.3145 54.0665 23.5841C54.3465 23.8779 54.4848 24.2547 54.4848 24.7213C54.4848 25.1879 54.3465 25.5578 54.0665 25.8585C53.8142 26.1281 53.472 26.2629 53.0469 26.2629C52.6217 26.2629 52.276 26.1281 52.0237 25.8585C51.7437 25.5613 51.6055 25.181 51.6055 24.7213C51.6055 24.2616 51.7437 23.8848 52.0237 23.5841C52.2795 23.3145 52.6217 23.1797 53.0469 23.1797ZM53.0469 23.7051C52.7807 23.7051 52.5698 23.8088 52.4178 24.0196C52.2864 24.1994 52.2207 24.4344 52.2207 24.7213C52.2207 25.0497 52.3072 25.3089 52.4765 25.4956C52.6252 25.658 52.8153 25.7375 53.0503 25.7375C53.3165 25.7375 53.5273 25.6338 53.6759 25.423C53.8073 25.2433 53.873 25.0082 53.873 24.7213C53.873 24.3929 53.7866 24.1337 53.6172 23.947C53.4685 23.7846 53.2819 23.7051 53.0503 23.7051H53.0469Z" fill="#097AD5"/>
                                <path d="M57.6354 23.2266V23.7416L56.1076 25.7049H57.6354V26.2199H55.3887V25.7049L56.9165 23.7416H55.3887V23.2266H57.6354Z" fill="#097AD5"/>
                                <path d="M59.3301 23.2266V26.2165H58.7148V23.2266H59.3301Z" fill="#097AD5"/>
                                <path d="M61.6348 23.2266H62.2536V25.3904C62.2536 25.6876 62.1602 25.9123 61.9701 26.0644C61.8042 26.1992 61.5761 26.2649 61.2892 26.2649C61.0023 26.2649 60.7811 26.1854 60.6048 26.0264C60.4389 25.8743 60.3594 25.6496 60.3594 25.3593V25.0862H60.9746V25.3489C60.9746 25.6081 61.0853 25.736 61.3099 25.736C61.5346 25.736 61.6383 25.622 61.6383 25.3904V23.2266H61.6348Z" fill="#097AD5"/>
                                <path d="M65.3045 26.2165L64.1016 24.1495V26.2165H63.4863V23.2266H64.1154L65.301 25.259V23.2266H65.9163V26.2165H65.301H65.3045Z" fill="#097AD5"/>
                                <path d="M67.7739 24.9272V25.7015H69.395V26.2165H67.1621V23.2266H69.3225V23.7416H67.7774V24.4191H69.2084V24.9341H67.7774L67.7739 24.9272Z" fill="#097AD5"/>
                                <path d="M72.326 26.2165L71.1231 24.1495V26.2165H70.5078V23.2266H71.1369L72.3225 25.259V23.2266H72.9378V26.2165H72.3225H72.326Z" fill="#097AD5"/>
                                <path d="M95.0006 23.1797H73.7324V26.2629H95.0006V23.1797Z" fill="#A31332"/>
                            </svg>

                        </Link>

                    </div>
                    <div onClick={()=>setBurger(!burger)} className={`sidebar__burger ${burger ? "active" : ""}`}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                <div className={`sidebar__menu-container ${burger ? "active" : ""}`}>
                    <div className="sidebar__nav">
                        <ul className={'sidebar__menu'}>
                            <li className={'sidebar__item'}><Link to="/products">{t('products_pages.title')} <BagIcon/></Link></li>
                            <li className={'sidebar__item'}><Link to="/categories">{t('categories_pages.title')} <CategoryIcon/></Link></li>
                            <li className={'sidebar__item'}><Link to="/orders">{t('orders_pages.title')} <CartIcon/></Link></li>
                            <li className={'sidebar__item'}><Link to="/attributes">{t('attributes_pages.title')} <TagIcon/></Link></li>
{/*                            <li className={'sidebar__item'}><Link to="/infoAttributes">{t('info_attributes_pages.title')} <InfoTagIcon/></Link></li>*/}
                            <li className={'sidebar__item'}><Link to="/transactions">{t('transactions_pages.title')} <CashIcon/></Link></li>
                            <li className={'sidebar__item'}><Link to="/baskets">{t('baskets_pages.title')} <UserIcon/></Link></li>


                            {/* <li className={'menu__item'}><Link className={'menu__link'} to="/settings">  Налаштування</Link></li>*/}
                        </ul>
                    </div>
                    <div className="sidebar__additional-nav">
                        <ul className={'sidebar__menu'}>
                            <li className={'sidebar__item'}><Link to="/mysettings">{t('menu.settings')}</Link></li>
                            <li className={'sidebar__item'}><Link onClick={()=>dispatch(asyncLogoutAction())} to="/logout">{t('menu.logout')}</Link></li>
                        </ul>
                    </div>
                </div>

            </div>
        </aside>
    );
};

export default Sidebar;