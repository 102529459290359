import {createAsyncThunk} from "@reduxjs/toolkit";
import OrdersService from "../../../api/OrdersService";
import {IOrder} from "../../../helpers/types";

export const asyncGetOrdersAction = createAsyncThunk(
    'orders/all',
    async (query:any, thunkAPI)=>{
        try {
            const {sort,page,limit} = query;
            let response = await OrdersService.findAll(sort,limit,page);
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);
export const asyncGetOrderAction = createAsyncThunk(
    'orders/one',
    async (id:number, thunkAPI)=>{
        try {
            let response = await OrdersService.findOne(id);
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);


export const asyncUpdateOrderAction = createAsyncThunk(
    'orders/update',
    async (order:IOrder, thunkAPI)=>{
        try {
            const {
                id,
                deliverType,
                answer,
              //  phone,
                status,

                client_bill,
                postcode_bill,
                company_bill,
                address_bill,
                city_bill,
                country_bill,
                land_phone_bill,
                phone_bill,
                email_bill,
                client_delivery,
                postcode_delivery,
                company_delivery,
                address_delivery,
                city_delivery,
                country_delivery,
                land_phone_delivery,
                phone_delivery,
                email_delivery
            } = order;

            let response = await OrdersService.update({
                deliverType,
                answer,

                status,
                client_bill,
                postcode_bill,
                company_bill,
                address_bill,
                city_bill,
                country_bill,
                land_phone_bill,
                phone_bill,
                email_bill,
                client_delivery,
                postcode_delivery,
                company_delivery,
                address_delivery,
                city_delivery,
                country_delivery,
                land_phone_delivery,
                phone_delivery,
                email_delivery
            }, Number(id));
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);

export const asyncDeleteOrderAction = createAsyncThunk(
    'orders/delete',
    async (id:number, thunkAPI)=>{
        try {
            let response = await OrdersService.delete(id);
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);