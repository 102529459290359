import {FC, ReactNode} from 'react';

import './CategoryProductList.scss'
import PlaceholderIcon from "../../atoms/icons/PlaceholderIcon";
import {Link} from "react-router-dom";
import Tag from "../../atoms/Tag/Tag";
import {getRandomColor} from "../../../helpers/scripts";
import Placeholder from "../../atoms/Placeholder/Placeholder";

interface ICategoryProductItem{
    id: number;
    image:string | null;
    title: ReactNode;
    category: string;
    price: number;
}

interface ICategoryProductList{
    link?:string
    items: ICategoryProductItem[];
}

const CategoryProductList:FC<ICategoryProductList> = ({link='',items=[]}) => {
    return (
        <div className="category-product-list">
            {items?.length ? items.map(item =>{
                return <div key={item.id} className="category-product-list__item">
                    <div className="category-product-list__item-product">
                        <Link to={`${link}/${item.id}`}>
                            <div className="category-product-list__item-wrapper">
                                <div className="category-product-list__item-image">
                                    <div className="category-product-list__item-category">
                                        {item.category ? <Tag color={getRandomColor(item.category)}>{item.category}</Tag>: ""}
                                    </div>
                                    {item.image ? <img src={item.image} alt=""/>: <PlaceholderIcon/>}
                                </div>
                                <div className="category-product-list__item-title"><span>{item.title}</span><span className={'category-product-list__item-price'}>${item.price}</span></div>
                            </div>
                        </Link>
                    </div>


                </div>
            }) : <Placeholder header={'Ups...'} message={"Results not found"}/>}

        </div>
    );
};

export default CategoryProductList;