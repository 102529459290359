import React, { FC, useEffect } from 'react';

import './StatisticsItems.scss';
import { useDispatchEx, useSelectorEx } from '../../../hooks/redux';

const StatisticsItems: FC = () => {
    const { ordersStatistics, basketsStatistics } = useSelectorEx(state => state.statistics);

    return (
        <div className="statistics-items">
            <div className="statistics-item">
                <div className="statistics-item__title">Income per day</div>
                <div className="statistics-item__content">
                    ${ordersStatistics?.day}
                </div>
            </div>

            <div className="statistics-item">
                <div className="statistics-item__title">Income per week</div>
                <div className="statistics-item__content">
                    ${ordersStatistics?.week}
                </div>
            </div>

            <div className="statistics-item">
                <div className="statistics-item__title">Income per month</div>
                <div className="statistics-item__content">
                    ${ordersStatistics?.month}
                </div>
            </div>

            <div className="statistics-item">
                <div className="statistics-item__title">Potential income in baskets</div>
                <div className="statistics-item__content">
                    ${basketsStatistics?.totalPrice} ({basketsStatistics?.totalCount} items)
                </div>
            </div>
        </div>
    );
};

export default StatisticsItems;
