import React, {FC, ReactNode, useState} from 'react';
import Input from "../../atoms/Input/Input";

import './RepeaterCheckbox.scss';
import Switch from "../../atoms/Switch/Switch";


interface IRepeater{
    name:string;
    title?:ReactNode;
    subtitle?:string;
    handleBlur?:()=>void;
    handleChange?:(value:any)=>void;
    items: any[];
    values:any[];
    errors:any;
    touched:any;
}

const RepeaterCheckbox:FC<IRepeater> = ({name,
                                    title,
                                    items=[],
                                    handleChange=()=>{},
                                    handleBlur=()=>{},
                                    values,
                                    errors,
                                    touched,
                                    subtitle=''
}) => {
    const [open,setOpen] = useState<boolean>(false);

    return (
        <div className={`repeater-checkbox-product ${open ? "active": ""}`}>
            <div className="repeater-checkbox-product__header">
                <div className="repeater-checkbox-product__title" onClick={()=>setOpen(!open)}><span>{title}</span>
                    <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.5 1L6.5 6L1.5 1" stroke="#C3C4C5" strokeWidth="1.06667" strokeLinecap="square"/>
                    </svg>
                </div>
            </div>

            <div className="repeater-checkbox-product__wrapper">
                <div className="repeater-checkbox-product__items">
                    {items.map(((item,index)=>{
                        return  <div key={index} className="repeater-checkbox-product__item">
                            <div className="repeater-checkbox-product__item-inner">
                                <div className="repeater-checkbox-product__item-inputs">
                                    <div className="repeater-checkbox-product__item-input">
                                        <div className="form-product__checkbox">
                                            <span>Compartment {index+1}</span>
                                            <Switch isChecked={values.filter((val:any)=>val.id===index)[0]?.value} onChange={(value)=> {
                                                handleChange({
                                                    id: index,
                                                    value: value
                                                })
                                            }} label={'isAvailable'}/>
                                        </div>
                                     </div>
                                </div>
                            </div>
                        </div>
                    }))}

                </div>
            </div>

        </div>
    );
};

export default RepeaterCheckbox;