import {FC, ReactNode} from 'react';
import './CategoryInfoList.scss'
import {Link} from "react-router-dom";
import TextAvatar from "../../atoms/TextAvatar/TextAvatar";
import Tag from "../../atoms/Tag/Tag";
import Placeholder from "../../atoms/Placeholder/Placeholder";

interface ICategoryInfoItem{
    keyID?:any
    id: number;
    title: string;
    date?: string;
    status?:any;
    total?: any;
}

interface ICategoryInfoList{
    link?:string
    items: ICategoryInfoItem[];
}

const CategoryInfoList:FC<ICategoryInfoList> = ({link='',items=[]}) => {
    return (
        <div className="category-info-list">
            {items?.length ? items.map(item =>{
                return <div key={item.keyID} className="category-info-list__item">
                    <Link to={`${link}/${item.id}`}>
                        <div className="category-info-list__item-wrapper">
                            <div className="category-info-list__item-image-container">
                                <div className="category-info-list__item-id">
                                   # {item.id}
                                </div>
                                <div className="category-info-list__item-image">
                                    <TextAvatar name={item.title} />
                                </div>
                                <div className="category-info-list__item-title">{item.title}</div>
                            </div>
                            <div className="category-info-list__item-details">
                                <div className="category-info-list__item-status">
                                    <Tag type={'status'} color={item.status.color}>{item.status.name}</Tag>
                                </div>
                                {item?.total ? <div className="category-info-list__item-price">
                                    <Tag type={'light-green'}>{item.total} {process.env.REACT_APP_CURRENCY}</Tag>
                                </div> : ""}
                                {item?.date ? <div className="category-info-list__item-date">
                                    {item.date}
                                </div> : ""}
                            </div>
                        </div>
                    </Link>

                </div>
            }) : <Placeholder header={'Ups...'} message={"Results not found"}/>}

        </div>
    );
};

export default CategoryInfoList;