import React, {FC, useEffect} from 'react';
import {useParams} from "react-router";
import {useDispatchEx, useSelectorEx} from "../../hooks/redux";
import {useFormik} from "formik";
import {valSchemaSettings} from "../../helpers/schemas";

import {
    asyncGetOrderAction,

    asyncUpdateOrderAction
} from "../../redux/slices/orders/ordersAction";
import {getDeliverType, getStatusType, getTimeDate} from "../../helpers/scripts";
import OrderProductItem from "../../components/old/atoms/OrderProductItem/OrderProductItem";
import Preloader from "../../components/atoms/Preloader/Preloader";

import HeaderPanel from "../../components/molecules/HeaderPanel/HeaderPanel";
import ErrorMessage from "../../components/old/atoms/ErrorMessage/ErrorMessage";
import Select from '../../components/atoms/Select/Select';
import Input from '../../components/atoms/Input/Input';

import './FormOrder.scss'
import TextArea from '../../components/atoms/TextArea/TextArea';
import PreloaderContent from "../../components/atoms/PreloaderContent/PreloaderContent";
import {useTranslation} from "react-i18next";
import {getOptionsListProduct} from "../../helpers/functions";

const Order:FC = () => {
    let {orderID} = useParams();

    const dispatch = useDispatchEx();

    const {order, isLoading} = useSelectorEx(state => state.orders);
    const {values,handleChange,errors,touched,handleSubmit,handleBlur,setFieldValue} = useFormik({
        initialValues: {
         //   client: order?.client as string,
            total: order?.items?.reduce((a:any,b:any)=>{
                return a+(b?.OrderItems?.price*b?.OrderItems?.count)
            },0),
            items: order?.items,
            email:order?.author?.email,
            createdAt:order?.createdAt,

            deliverType:order?.deliverType,
            payment:order?.payment,
          //  phone:order?.phone,
            status:order?.status,
            answer: order?.answer,
            comment: order?.comment,
            updatedAt:order?.updatedAt,

            client_bill: order?.client_bill as string,
            postcode_bill: order?.postcode_bill as string,
            company_bill: order?.company_bill as string,
            address_bill: order?.address_bill as string,
            city_bill: order?.city_bill as string,
            country_bill: order?.country_bill as string,
            land_phone_bill: order?.land_phone_bill as string,
            phone_bill: order?.phone_bill as string,
            email_bill: order?.email_bill as string,
            client_delivery: order?.client_delivery as string,
            postcode_delivery: order?.postcode_delivery as string,
            company_delivery: order?.company_delivery as string,
            address_delivery: order?.address_delivery as string,
            city_delivery: order?.city_delivery as string,
            country_delivery: order?.country_delivery as string,
            land_phone_delivery: order?.land_phone_delivery as string,
            phone_delivery: order?.phone_delivery as string,
            email_delivery: order?.email_delivery as string,
        },
        enableReinitialize: true,
        validationSchema:valSchemaSettings,
        onSubmit: (value) => formHandle(value),
    });
    const formHandle = (value: any)=>{
        dispatch(asyncUpdateOrderAction({
            id:Number(orderID),
            ...value,
        },));
    };

    useEffect(()=>{
        dispatch(asyncGetOrderAction(Number(orderID)));
    },[]);

    const { t } = useTranslation();

  //  if(!order) return <Preloader/>;
/*    const totalPrice = order?.items?.reduce((a:any,b:any)=>{
        return a+(b?.OrderItems?.price*b?.OrderItems?.count)
    },0)*/


    return (
        <>
            <div className="container">
                {!isLoading || order ?
                    <>
                        <HeaderPanel title={`${t('orders_pages.page.title')} № ${10000+Number(orderID)} ${getTimeDate(order?.createdAt as string)}`} onSave={()=>handleSubmit()} />
                        <form onSubmit={handleSubmit}> <div className="order-form">
                            <div className="order-form__wrapper">
                                <div className="order-form__block">
                                    <div className="order-form__input">
                                        <Input className={errors.client_bill && touched.client_bill ? "error" : ""} value={values.client_bill} onBlur={handleBlur} onChange={handleChange} name={'client_bill'} placeholder={t('orders_pages.fields.client_bill')} />
                                        <ErrorMessage message={errors.client_bill && touched.client_bill && errors.client_bill} />
                                    </div>

                                    <div className="order-form__input">
                                        <Input className={errors.postcode_bill && touched.postcode_bill ? "error" : ""} value={values.postcode_bill} onBlur={handleBlur} onChange={handleChange} name={'postcode_bill'} placeholder={t('orders_pages.fields.postcode_bill')} />
                                        <ErrorMessage message={errors.postcode_bill && touched.postcode_bill && errors.postcode_bill} />
                                    </div>

                                    <div className="order-form__input">
                                        <Input className={errors.company_bill && touched.company_bill ? "error" : ""} value={values.company_bill} onBlur={handleBlur} onChange={handleChange} name={'company_bill'} placeholder={t('orders_pages.fields.company_bill')} />
                                        <ErrorMessage message={errors.company_bill && touched.company_bill && errors.company_bill} />
                                    </div>

                                    <div className="order-form__input">
                                        <Input className={errors.address_bill && touched.address_bill ? "error" : ""} value={values.address_bill} onBlur={handleBlur} onChange={handleChange} name={'address_bill'} placeholder={t('orders_pages.fields.address_bill')} />
                                        <ErrorMessage message={errors.address_bill && touched.address_bill && errors.address_bill} />
                                    </div>

                                    <div className="order-form__input">
                                        <Input className={errors.city_bill && touched.city_bill ? "error" : ""} value={values.city_bill} onBlur={handleBlur} onChange={handleChange} name={'city_bill'} placeholder={t('orders_pages.fields.city_bill')} />
                                        <ErrorMessage message={errors.city_bill && touched.city_bill && errors.city_bill} />
                                    </div>

                                    <div className="order-form__input">
                                        <Input className={errors.country_bill && touched.country_bill ? "error" : ""} value={values.country_bill} onBlur={handleBlur} onChange={handleChange} name={'country_bill'} placeholder={t('orders_pages.fields.country_bill')} />
                                        <ErrorMessage message={errors.country_bill && touched.country_bill && errors.country_bill} />
                                    </div>

                                    <div className="order-form__input">
                                        <Input className={errors.land_phone_bill && touched.land_phone_bill ? "error" : ""} value={values.land_phone_bill} onBlur={handleBlur} onChange={handleChange} name={'land_phone_bill'} placeholder={t('orders_pages.fields.land_phone_bill')} />
                                        <ErrorMessage message={errors.land_phone_bill && touched.land_phone_bill && errors.land_phone_bill} />
                                    </div>

                                    <div className="order-form__input">
                                        <Input className={errors.phone_bill && touched.phone_bill ? "error" : ""} value={values.phone_bill} onBlur={handleBlur} onChange={handleChange} name={'phone_bill'} placeholder={t('orders_pages.fields.phone_bill')} />
                                        <ErrorMessage message={errors.phone_bill && touched.phone_bill && errors.phone_bill} />
                                    </div>

                                    <div className="order-form__input">
                                        <Input className={errors.email_bill && touched.email_bill ? "error" : ""} value={values.email_bill} onBlur={handleBlur} onChange={handleChange} name={'email_bill'} placeholder={t('orders_pages.fields.email_bill')} />
                                        <ErrorMessage message={errors.email_bill && touched.email_bill && errors.email_bill} />
                                    </div>
                                </div>
                                <div className="order-form__block">
                                    <div className="order-form__input">
                                        <Input className={errors.client_delivery && touched.client_delivery ? "error" : ""} value={values.client_delivery} onBlur={handleBlur} onChange={handleChange} name={'client_delivery'} placeholder={t('orders_pages.fields.client_delivery')} />
                                        <ErrorMessage message={errors.client_delivery && touched.client_delivery && errors.client_delivery} />
                                    </div>

                                    <div className="order-form__input">
                                        <Input className={errors.postcode_delivery && touched.postcode_delivery ? "error" : ""} value={values.postcode_delivery} onBlur={handleBlur} onChange={handleChange} name={'postcode_delivery'} placeholder={t('orders_pages.fields.postcode_delivery')} />
                                        <ErrorMessage message={errors.postcode_delivery && touched.postcode_delivery && errors.postcode_delivery} />
                                    </div>

                                    <div className="order-form__input">
                                        <Input className={errors.company_delivery && touched.company_delivery ? "error" : ""} value={values.company_delivery} onBlur={handleBlur} onChange={handleChange} name={'company_delivery'} placeholder={t('orders_pages.fields.company_delivery')} />
                                        <ErrorMessage message={errors.company_delivery && touched.company_delivery && errors.company_delivery} />
                                    </div>

                                    <div className="order-form__input">
                                        <Input className={errors.address_delivery && touched.address_delivery ? "error" : ""} value={values.address_delivery} onBlur={handleBlur} onChange={handleChange} name={'address_delivery'} placeholder={t('orders_pages.fields.address_delivery')} />
                                        <ErrorMessage message={errors.address_delivery && touched.address_delivery && errors.address_delivery} />
                                    </div>

                                    <div className="order-form__input">
                                        <Input className={errors.city_delivery && touched.city_delivery ? "error" : ""} value={values.city_delivery} onBlur={handleBlur} onChange={handleChange} name={'city_delivery'} placeholder={t('orders_pages.fields.city_delivery')} />
                                        <ErrorMessage message={errors.city_delivery && touched.city_delivery && errors.city_delivery} />
                                    </div>

                                    <div className="order-form__input">
                                        <Input className={errors.country_delivery && touched.country_delivery ? "error" : ""} value={values.country_delivery} onBlur={handleBlur} onChange={handleChange} name={'country_delivery'} placeholder={t('orders_pages.fields.country_delivery')} />
                                        <ErrorMessage message={errors.country_delivery && touched.country_delivery && errors.country_delivery} />
                                    </div>

                                    <div className="order-form__input">
                                        <Input className={errors.land_phone_delivery && touched.land_phone_delivery ? "error" : ""} value={values.land_phone_delivery} onBlur={handleBlur} onChange={handleChange} name={'land_phone_delivery'} placeholder={t('orders_pages.fields.land_phone_delivery')} />
                                        <ErrorMessage message={errors.land_phone_delivery && touched.land_phone_delivery && errors.land_phone_delivery} />
                                    </div>

                                    <div className="order-form__input">
                                        <Input className={errors.phone_delivery && touched.phone_delivery ? "error" : ""} value={values.phone_delivery} onBlur={handleBlur} onChange={handleChange} name={'phone_delivery'} placeholder={t('orders_pages.fields.phone_delivery')} />
                                        <ErrorMessage message={errors.phone_delivery && touched.phone_delivery && errors.phone_delivery} />
                                    </div>

                                    <div className="order-form__input">
                                        <Input className={errors.email_delivery && touched.email_delivery ? "error" : ""} value={values.email_delivery} onBlur={handleBlur} onChange={handleChange} name={'email_delivery'} placeholder={t('orders_pages.fields.email_delivery')} />
                                        <ErrorMessage message={errors.email_delivery && touched.email_delivery && errors.email_delivery} />
                                    </div>
                                </div>




                                <div className="order-form__input">
                                    <Select
                                        defaultValue={order?.deliverType as string}
                                        onChange={(value)=> setFieldValue('deliverType', value)}
                                        className={errors.deliverType && touched.deliverType ? "error": ""}

                                        placeholder={t('orders_pages.fields.deliverType')}
                                        items={[
                                            {
                                                value: 'newpost',
                                                name:getDeliverType('newpost'),
                                            },
                                            {
                                                value: 'meest',
                                                name:getDeliverType('meest'),
                                            },
                                            {
                                                value:'urkpost',
                                                name:getDeliverType('urkpost'),
                                            },
                                        ]}
                                    />
                                </div>

                                <div className="order-form__input">
                                    <Select
                                        defaultValue={order?.payment as string}
                                        onChange={(value)=> setFieldValue('payment', value)}
                                        className={errors.payment && touched.payment ? "error": ""}

                                        placeholder={t('orders_pages.fields.payment.title')}
                                        items={[
                                            {
                                                value: 'online',
                                                name:t('orders_pages.fields.payment.online'),
                                            },
                                            {
                                                value: 'payment',
                                                name:t('orders_pages.fields.payment.payment'),
                                            },
                                        ]}
                                    />
                                </div>
                                <div className="order-form__input">
                                    <Select
                                        defaultValue={order?.status?.toString()}
                                        onChange={(value)=> setFieldValue('status', value)}
                                        className={errors.payment && touched.payment ? "error": ""}

                                        placeholder={t('orders_pages.fields.status')}
                                        items={[
                                            {
                                                value: '1',
                                                name: getStatusType(1).name,
                                            },
                                            {
                                                value: '2',
                                                name:getStatusType(2).name,
                                            },
                                            {
                                                value: '3',
                                                name:getStatusType(3).name,
                                            },
                                            {
                                                value: '4',
                                                name:getStatusType(4).name,
                                            },
                                            {
                                                value: '5',
                                                name:getStatusType(5).name,
                                            },
                                            {
                                                value: '6',
                                                name:getStatusType(6).name,
                                            },
                                        ]}
                                    />
                                </div>
                                <div className="order-form__input">
                                    <Input className={errors.email && touched.email ? "error": ""} value={order?.author?.email} onBlur={handleBlur} onChange={handleChange} name={'email'} placeholder={'Email'} />
                                    <ErrorMessage message={errors.email && touched.email && errors.email}/>
                                </div>
                                <div className="order-form__input">
                                    <TextArea className={errors.comment && touched.comment ? "error": ""} value={order?.comment} onBlur={handleBlur} onChange={handleChange} name={'comment'} placeholder={t('orders_pages.fields.comment')} />
                                    <ErrorMessage message={errors.comment && touched.comment && errors.comment}/>
                                </div>
                                <div className="order-form__input">
                                    <TextArea className={errors.answer && touched.answer ? "error": ""} value={values.answer} onBlur={handleBlur} onChange={handleChange} name={'answer'} placeholder={t('orders_pages.fields.answer')} />
                                    <ErrorMessage message={errors.answer && touched.answer && errors.answer}/>
                                </div>
                            </div>
                            <div className="order-form__inner">
                                <div className="order-form__items">
                                    {order?.items ? order?.items?.map((item:any)=>{
                                        const currentProductType = item?.OrderItems?.productType ? getOptionsListProduct(JSON.parse(item?.OrderItems?.productType),item,item?.section_count)  : []
                                        return <OrderProductItem key={item.id} image={item.image as string} title={item.title as string} count={item?.OrderItems?.count} price={item?.OrderItems?.price} productType={currentProductType}/>
                                    }): "No products"}
                                </div>
                            </div>
                        </div></form>
                    </>
                    :
                    <PreloaderContent/>
                }

            </div>
        </>
    );
};

export default Order;