import React, {FC} from 'react';
import {Button, Input} from "antd";
import {useFormik} from "formik";
import {Navigate, useLocation} from 'react-router-dom';
import { valSchemaLogin } from '../helpers/schemas';
import {useDispatchEx, useSelectorEx} from '../hooks/redux';
import {
    asyncRegistrationAction,
} from "../redux/slices/auth/authAction";
import {GoogleLogin, GoogleOAuthProvider} from "@react-oauth/google";

import GoogleButton from "../components/atoms/GoogleButton/GoogleButton";
import MessageErrorForm from "../components/old/atoms/MessageErrorForm/MessageErrorForm";
import ErrorMessage from "../components/old/atoms/ErrorMessage/ErrorMessage";

interface IRegistration {
    email: string;
    password: string;
}

const Registration:FC = () => {
    const {error} = useSelectorEx(state => state.auth);

    const dispatch = useDispatchEx();
    const location = useLocation();
    const registrationHandle = (values: IRegistration)=>{
        dispatch(asyncRegistrationAction(values));
    };

    const {
        errors,
        touched,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        resetForm
    } = useFormik({
        initialValues: {
            email: '',
            password: '',
            phone: ''
        },
        validationSchema:valSchemaLogin,
        onSubmit: value => registrationHandle(value),
    });

    const token = localStorage.getItem('token');
    if(token) return <Navigate to={'/'} state={{from: location}} replace />
    return (
        <section className={'login'}>
            <div className="login__wrap">
                <h1 className="h1">Registration</h1>
                <form onSubmit={handleSubmit} >
                    <div className="login__input">
                        <Input name={'email'} size={'large'} placeholder="Email" onChange={handleChange} onBlur={handleBlur} value={values.email} />
                        <ErrorMessage message={errors.email}/>
                    </div>
                    <div className="login__input">
                        <Input name={'phone'} size={'large'} placeholder="Phone" onChange={handleChange} onBlur={handleBlur} value={values.phone} />
                        <ErrorMessage message={errors.phone}/>
                    </div>
                    <div className="login__input">
                        <Input name={'password'} type={'password'} size={'large'} onChange={handleChange} onBlur={handleBlur} value={values.password}  placeholder="Password" />
                        <ErrorMessage message={errors.password}/>
                        <ErrorMessage message={error}/>
                    </div>
                    <div className="login__button">
                        <Button htmlType={'submit'} type="primary">Sign Up</Button>
                        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string} >
                           <GoogleButton/>
                        </GoogleOAuthProvider>
                    </div>
                </form>
            </div>
        </section>
    );
};

export default Registration;