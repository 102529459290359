import React, {FC, useState} from 'react';
import PlaceholderIcon from "../../../atoms/icons/PlaceholderIcon";
import Tag from "../../../atoms/Tag/Tag";


interface IOrderProductItem {
    image:string;
    title:string
    count:number
    price:number;
    productType?:any[]
}

const OrderProductItem:FC<IOrderProductItem> = ({image = 'https://via.placeholder.com/100x100/',title,count,price,productType=[]}) => {
    const [open, setOpen] = useState(false);
    return (
        <div className={`order-form__item ${open ? "active": ""}`}>
            <div className="order-form__item-wrapper" onClick={()=>setOpen(!open)}>
                <div className="order-form__item-image-container">
                    <div className="order-form__item-image">
                        {image ?  <img src={image ? process.env.REACT_APP_URL_PRODUCT_IMG as string+image : image} alt=""/>: <PlaceholderIcon/>}

                    </div>
                    <div className="order-form__item-title">{title}</div>
                </div>

                <div className="order-form__controls">
                    <div className="order-form__block">{count} x ${price}</div>
                    <div className="order-form__block"><Tag type={'light-green'}>${price*count}</Tag></div>
                </div>
            </div>
            <div className="order-form__item-details">
                <div className="order-form__item-details-wrapper">
                    {productType.length ? productType.map((configItem:any, index:number) => {
                        return <div key={configItem.title} >
                            <span>{configItem.title}</span> {configItem.value} <br/>
                        </div>
                    }) : ""}
                </div>
            </div>
        </div>
    );
};

export default OrderProductItem;